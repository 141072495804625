.panel-background {
  box-sizing: border-box;
  display: flex;
  background-color: black;
  width: 100%;
}

.flex-left {

  flex: 0 0 60%;
}

.flex-right {
  flex: 0 0 40%;
}

.bg-film {
  background-image: url('../../../assets/frontpage/introduction/film-gallery.jpeg'); // Use the path to your background image
  background-position: right;
  background-attachment: fixed; /* Ensures the background image doesn't scroll */
  background-repeat: no-repeat;
}

.introduction-text {
  font-size: 1.5rem;
  color: white;
  margin: 40px;

  text-shadow: 3px 2px 2px black;

  h2 {
    font-size: 3rem;
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  &.fade-in-left {
    animation: fadeInLeft 1s ease-in-out;
  }

  &.hidden {
    opacity: 0;
  }
}

@media (max-width: 768px) {
  .panel-background {
    height: auto;
    background-color: black;

    .flex-left {
      flex: 0 1 auto;
    }

    .introduction-text {
      font-size: 1.2rem;
  
      h2 {
        font-size: 2.5rem;
      }
    }
  
    // hide film background for mobile view
    .bg-film {
      display: none;
    }
  }

}